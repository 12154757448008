import React from "react";
import ImageGallery from 'react-image-gallery';

import SectionLayoutGrid from "../../layouts/SectionLayoutGrid"

import {Header2} from "../../styled/typography/Typography";
import Col from "../../styled/grid/Col";

import "react-image-gallery/styles/css/image-gallery.css";

const Gallery = ({title, images}) => {


    return (
        <SectionLayoutGrid id={"Gallery"} customBackground="middle">
            {title &&
            <Col marginBottom="small">
                <Header2 color="white">
                    {title}
                </Header2>
            </Col>
            }
            <Col>
                <ImageGallery
                    items={images.map((item) => (
                        {
                            original: item.url,
                            thumbnail: item.url,
                        }
                    ))}
                    showPlayButton={false}
                    showIndex={true}
                    showBullets={true}
                />
            </Col>
        </SectionLayoutGrid>
    )
}

export default Gallery;